import { BsMusicNoteBeamed } from "react-icons/bs";
export const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
  handleNext,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };
  return (
    <div>
      <audio
        src={currentTrack.src}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
      />
      <div className="audio-info">
        <div className="audio-image">
          {currentTrack.thumbnail ? (
            <img src={currentTrack.thumbnail} alt="Portada de l'àlbum 'Encara' feta per Manon Raupp" loading="lazy" style={{ backgroundColor: 'transparent' }} />
          ) : (
            <div className="icon-wrapper">
              <span className="audio-icon">
                <BsMusicNoteBeamed />
              </span>
            </div>
          )}
        </div>
        <div className="title">
          <span>{currentTrack.title}</span>
        </div>
        <div className="author">
          <span >{currentTrack.author}</span>
        </div>
      </div>
    </div>
  );
};
