import { useState, useEffect } from "react";

function Countdown() {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const startTime = 6;
  const endTime = 20;

  const formatTime = (hours, minutes, seconds) => {
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();
      const currentSecond = currentDate.getSeconds();

      if (currentHour >= startTime && currentHour < endTime) {
        // Calcular el tiempo restante hasta la hora de finalización (6:00 AM)
        let remainingHours = endTime - 1 - currentHour;
        const remainingMinutes = 59 - currentMinute;
        const remainingSeconds = 59 - currentSecond;

        setTime({
          hours: remainingHours,
          minutes: remainingMinutes,
          seconds: remainingSeconds,
        });

        if (remainingHours === 0 && remainingMinutes === 0 && remainingSeconds === 0) {
          // Cuando el contador llega a 00:00:00, recargar la página
          window.location.reload();
        }
      }
    }, 1000); // Actualizar cada segundo

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <p>
      {formatTime(time.hours, time.minutes, time.seconds)}
    </p>
  );
}

export default Countdown;
