import { useState, useEffect, useRef, useCallback } from "react";
import {
  IoPlayBackOutline,
  IoPlayForwardOutline,
  IoPlaySkipBackOutline,
  IoPlaySkipForwardOutline,
  IoPlayOutline,
  IoPauseOutline,
  IoVolumeMuteOutline,
  IoVolumeLowOutline,
  IoVolumeHighOutline,
} from "react-icons/io5";

export const Controls = ({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  tracks,
  trackIndex,
  setTrackIndex,
  setCurrentTrack,
  handleNext,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const playAnimationRef = useRef();
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);

  const repeat = useCallback(() => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);
      progressBarRef.current.value = currentTime;
      progressBarRef.current.style.setProperty(
        "--range-progress",
        `${(progressBarRef.current.value / duration) * 100}%`
      );
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const skipForward = () => {
    audioRef.current.currentTime += 15;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  const handlePrevious = () => {
    if (trackIndex === 0) {
      let lastTrackIndex = tracks.length - 1;
      setTrackIndex(lastTrackIndex);
      setCurrentTrack(tracks[lastTrackIndex]);
    } else {
      setTrackIndex((prev) => prev - 1);
      setCurrentTrack(tracks[trackIndex - 1]);
    }
  };

  return (
    <div className="controls-wrapper">
      <div className="controls">
        <button onClick={handlePrevious}>
          <IoPlaySkipBackOutline style={{ color: "#272727" }} />
        </button>
        <button onClick={skipBackward}>
          <IoPlayBackOutline style={{ color: "#272727" }} />
        </button>

        <button onClick={togglePlayPause}>
          {isPlaying ? (
            <IoPauseOutline style={{ color: "#272727" }} />
          ) : (
            <IoPlayOutline style={{ color: "#272727" }} />
          )}
        </button>
        <button onClick={skipForward}>
          <IoPlayForwardOutline style={{ color: "#272727" }} />
        </button>
        <button onClick={handleNext}>
          <IoPlaySkipForwardOutline style={{ color: "#272727" }} />
        </button>
      </div>
      {/*<div className="volume">
        <button className="disappear" onClick={()=>setMuteVolume((prev)=>!prev)}>
            {muteVolume || volume < 5? (
                <IoVolumeMuteOutline style={{ color: "#272727" }} />
            ): volume < 40 ? (
                <IoVolumeLowOutline style={{ color: "#272727" }}/>
            ):(
                <IoVolumeHighOutline style={{ color: "#272727" }}/>
            )}
        </button>
        <input
        className="disappear"
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
          style={{
            background: `linear-gradient(to right, #386ab3 ${volume}%, #272727 ${volume}%)`,
          }}
        />
      </div>
        */}
    </div>
  );
};
