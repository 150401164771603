import astrollavi from './BipolarYouth Project - encara 2 - 02 astrollavi 2.mp3';
import encara from './ENCARA2 (2).png';
import murMurs from './BipolarYouth Project - encara 2 - 01 mur murs 2.wav';
import arnica from './BipolarYouth Project - encara 2 - 03 àrnica 2.mp3';
import cotxe from './BipolarYouth Project - encara 2 - 04 cotxe- 2.wav';
import palimpsest from './BipolarYouth Project - encara 2 - 05 palimpsest 2.wav';
import clepsidra from './BipolarYouth Project - encara 2 - 06 clepsidra 2.wav';
import arnica_img from './ARNICA (2).png';

export const tracks = [
    {
        title: 'Mur Murs',
        src: murMurs,
        author: 'Nil Nadal',
        thumbnail: encara,
    },
    {
        title: 'Astrollavi',
        src: astrollavi,
        author: 'Nil Nadal',
        thumbnail: encara,
    },
    {
        title: 'Àrnica',
        src: arnica,
        author: 'Nil Nadal',
        thumbnail: arnica_img,
    },
    {
        title: 'Cotxe',
        src: cotxe,
        author: 'Nil Nadal',
        thumbnail: encara,
    },
    {
        title: 'Riba',
        src: palimpsest,
        author: 'Nil Nadal',
        thumbnail: encara,
    },
    {
        title: 'Clepsidra',
        src: clepsidra,
        author: 'Nil Nadal',
        thumbnail: encara,
    }

]