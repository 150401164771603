import { useState, useRef } from "react";
import { tracks } from "../data/tracks";
import { DisplayTrack } from "./DisplayTrack";
import { Controls } from "./Controls";
import { ProgressBar } from "./ProgressBar";
import "../styles/audio-player.css";
import "../styles/customize-progress-bar.css";
import { IoArrowBack } from "react-icons/io5";

export const AudioPlayer = ({setIsListening}) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(null);
  const progressBarRef = useRef();

  const handleNext = () => {

    if (trackIndex >= tracks.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(tracks[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(tracks[trackIndex + 1]);
    }

   // audioRef.current.currentTime = 0;
   //audioRef.current.play();
  };


  return (
    <>
      <div className="audio-player">
        <div className="inner">
          <div className="buttonBack">
            <button onClick={() => setIsListening(false)}>
              <IoArrowBack style={{ color: "#272727" }} />
            </button>
          </div>

          <DisplayTrack
            {...{
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
            }}
          />
          <ProgressBar
            {...{
              progressBarRef,
              audioRef,
              timeProgress,
              duration,
              handleNext,
            }}
          />
          <Controls
            {...{
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress,
              tracks,
              trackIndex,
              setTrackIndex,
              setCurrentTrack,
              handleNext,
            }}
          />
        </div>
      </div>
    </>
  );
};
