import image from "./data/images/img_1(1).png";
import imageMobile from "./data/images/img_1_mobile(1).png";
import bandcamp from "./data/images/bandcamp.png";
import spotify from "./data/images/spotify.png";
import instagram from "./data/images/instagram.png";
import applemusic from "./data/images/applemusic.png";
import iconClickable from "./data/images/icon.png";
import "./styles/main.css";
import Countdown from "./components/Countdown";

function Main({ isVisible, setIsListening }) {
  return (
    <>
      <div className="app-hidden">
        <div className="item-left">
          <img
            className="responsive-image hidden-desktop"
            src={image}
            alt="imatge de l'artista Nil Nadal"
            loading="lazy"
            style={{ backgroundColor: 'transparent' }}
          />{" "}
          <img
            className="responsive-image hidden-mobile"
            src={imageMobile}
            alt="imatge de l'artista Nil Nadal"
            loading="lazy"
            style={{ backgroundColor: 'transparent' }}
          />
          <div className="contacts">
            <a
              href="https://open.spotify.com/artist/1WjqKYQEaT4yJwiH1mcXxh"
              target="_blank" rel="noopener noreferrer"
            >
              <img className="spotify" src={spotify} alt="Spotify"></img>
            </a>
            <a href="https://nilnadal.bandcamp.com/" target="_blank" rel="noopener noreferrer">
              <img className="bandcamp" src={bandcamp} alt="Bandcamp"></img>
            </a>
            <a
              href="https://www.instagram.com/en.ncara/?hl=es"
              target="_blank" rel="noopener noreferrer"
            >
              <img className="instagram" src={instagram} alt="Instagram"></img>
            </a>
            <a
              href="https://music.apple.com/es/artist/nil-nadal/1455594593"
              target="_blank" rel="noopener noreferrer"
            >
              <img
                className="applemusic"
                src={applemusic}
                alt="Apple Music"
              ></img>
            </a>
          </div>
        </div>

        <div className="item-right">
          <div className="inner-right-box">
            <div id="title">
              <span style={{ fontWeight: "bold" }}>Nil Nadal</span>
              <p> Encara</p>
            </div>
            {isVisible ? (
              <div>
                <p className="static-text">Àlbum ja disponible</p>
                <div className="timer">
                  <div className="button"  onClick={() => setIsListening(true)}>
                    <span
                      className="text-button"
                    >
                      escolta'l aquí
                    </span>
                    <img className="icon-clickable"
                src={iconClickable}
                alt="icon clickable"></img>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className="static-text">Àlbum disponible en</p>
                <div className="timer">
                  {<Countdown/>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
