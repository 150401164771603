import "./app.css";
import Main from "./Main";
import { AudioPlayer } from "./components/AudioPlayer";
import { useState} from "react";
function App() {
  const startTime = 20;
  const endTime = 6;

  const isVisible = () => {
    if (new Date().getHours() >= startTime || new Date().getHours() < endTime) {
      return true;
    } else {
      return false;
    }
  };
  const [isListening,setIsListening] = useState(false);
  return (
    <>{isListening ? <AudioPlayer setIsListening={setIsListening}  />:<Main isVisible={isVisible()} setIsListening={setIsListening}/>}</>
  );
}

export default App;
